/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/swiper@5.2.1/js/swiper.min.js
 * - /npm/jquery-smooth-scroll@2.2.0/jquery.smooth-scroll.min.js
 * - /npm/lazysizes@4.1.4/lazysizes.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-waypoints@2.0.5/waypoints.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
